.animated-component {
    opacity: 0;
    transform: translateY(100px);
    transition:
        opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1),
        transform 1s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.animated-component.in-view {
    transform: translateY(0);
    opacity: 1;
}

@keyframes myAnimation {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.fade-in-down {
    animation: fadeInDown 0.5s ease-in-out forwards;
}

.fade-out-up {
    animation: fadeOutUp 0.5s ease-in-out forwards;
}
